<template>
  <div class="mobile__view">
    <div class="d-flex justify-space-between align-center">
      <v-btn icon @click="setShowQuestionCards(false)">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <profile-drop-down/>
    </div>
    <div v-if="fetchingCriteriaTopics" class="d-flex justify-center">
      <v-progress-circular indeterminate :color="$vuetify.theme.themes.light.enumBlue.enumBlue"/>
    </div>
    <div v-else>
      <h2 class="test__title">{{criteriaTopicsInView.title}}</h2>
      <div class="mt-10 questions__list">
        <criteria-question-card
            @click="$emit('get-questions', item.id)"
            v-for="(item, i) in criteriaTopicsInView.items"
            :key="i"
            :title="item.title"
            :number_of_questions="item.number_of_questions"
            :duration="item.duration"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import CriteriaQuestionCard from "@/components/jobScreeningCriteria/CriteriaQuestionCard";
import {mapMutations, mapState} from "vuex";
export default {
  name: "MobileViewForCriteriaQuestionCardList",
  components: {CriteriaQuestionCard, ProfileDropDown},
  computed: {
    ...mapState('jobScreeningCriteria', ['criteriaTopicsInView', 'fetchingCriteriaTopics'])
  },
  methods: {
    ...mapMutations('jobScreeningCriteria', ['setShowQuestionCards', 'setShowSampleQuestions'])
  }
}
</script>

<style scoped lang="scss">
.mobile__view {
  z-index: 7;
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  padding: 10px 10px;

  h2.test__title {
    font-size: 20px;
    font-weight: bold;
    font-family: "DM Sans", serif;
  }

  .questions__list {
    height: calc(100vh - 116px);
    overflow-y: auto;
  }
}
</style>