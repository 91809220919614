<template>
  <div class="tabs__wrapper">
    <div class="slider" ref="slider" :style="{top: getSliderPosition}"/>
    <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="[{'active': tab.title === activeTab}]"
        class="tab-item d-flex align-center"
        @click="[onItemClick($event, tab)]">
      <span class="font-weight-400">{{tab.title}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabs: Array,
    activeItem: [String]
  },
  data: () => ({
    activeTab: ''
  }),
  watch: {
    activeItem: {
      handler: function (activeItem) {
        if (activeItem) this.activeTab = activeItem
      },
      immediate: true
    }
  },
  computed: {
    getSliderPosition() {
      const tabItemHeight = 45
      let currentTabIndex = this.tabs.findIndex(tab => tab.title === this.activeTab)
      return currentTabIndex * tabItemHeight + 'px'
    }
  },
  methods: {
    onItemClick(e, tab) {
      if (tab.title === this.activeTab) {
        this.$emit('click', {name: tab.title, id: tab.id})
        return
      }
      this.activeTab = tab.title
      let actualTarget = e.target.children[0]
      actualTarget = actualTarget ? actualTarget : e.target
      this.setSliderWidth(actualTarget)
      this.$emit('change', {name: tab.title, id: tab.id})
    },
    setSliderWidth(activeSpan) {
      if (!this.activeTab) return

      this.$refs.slider.style.width = activeSpan?.offsetWidth + 30 + 'px'
    }
  }
}
</script>

<style scoped lang="scss">
.tabs__wrapper {
  position: relative;
  overflow-y: hidden;
  .slider {
    position: absolute;
    transition: all .2s ease-in-out;
    height: 45px;
    min-width: 100px;
    padding: 0 10px;
    background: #F7FCFF;
    border-radius: 20px;
    //z-index: -1;
  }

  .tab-item {
    height: 45px;
    min-width: 100px;
    padding: 0 10px;
    position: relative;
    cursor: pointer;

    &.active {
      span {
        font-weight: 700;
        color: #008EEF;
      }
    }

    span {
      padding: 0 10px;
      font-family: "DM Sans", serif;
      color: #475661;
      transition: all .1s;
    }
  }
}
</style>