<template>
  <div class="mobile__view">
    <div class="d-flex justify-space-between align-center">
      <v-btn icon @click="setShowSampleQuestions(false)">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <profile-drop-down/>
    </div>
    <h2 class="test__title">{{criteriaTopicsInView.title}}</h2>
    <div class="mt-10 questions__list">
      <sample-question-card
        v-for="(item, i) in sampleQuestions"
        :key="i"
        :options="item.options"
        :order="i + 1"
        :question="item.question"
        :answer="item.answer"
        :expand="item.expand"
        :id="item.id"
        />
    </div>
    <div>
      <v-btn class="white--text text-capitalize" :color="$vuetify.theme.themes.light.enumBlue.enumBlue" depressed block>Use Test for Screening</v-btn>
    </div>
    <bottom-navigation/>
  </div>
</template>

<script>
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import {mapMutations, mapState} from "vuex";
import SampleQuestionCard from "@/components/jobScreeningCriteria/SampleQuestionCard";
import BottomNavigation from "@/components/dashboard/reuseables/BottomNavigation";

export default {
  name: "MobileViewForSampleQuestionsList",
  components: {BottomNavigation, SampleQuestionCard, ProfileDropDown},
  computed: {
    ...mapState('jobScreeningCriteria', ['sampleQuestions', 'criteriaTopicsInView'])
  },
  methods: {
    ...mapMutations('jobScreeningCriteria', ['setShowSampleQuestions'])
  }
}
</script>

<style scoped lang="scss">
.mobile__view {
  z-index: 7;
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  padding: 10px 10px;

  h2.test__title {
    font-size: 20px;
    font-weight: bold;
    font-family: "DM Sans", serif;
  }
  
  .questions__list {
    height: calc(100vh - 116px - 64px - 45px);
    overflow-y: auto;
  }
}
</style>