<template>
  <div class="sample__questions_wrapper mb-5 md:tw-mr-4" :class="expand?'color-wrapper':''">
    <div class="d-flex question">
      <span class="mr-1">{{order}}.</span>
      <span class="question" v-html="question"/>
    </div>
    <div v-if="image" class="tw-my-4">
      <img :src="image" alt="" class="img-size"/>
    </div>
    <v-btn text small class="px-2" :color="$vuetify.theme.themes.light.enumGrey3" @click="[toggleExpand({id, expand: !expand})]">
      <span class="d-flex align-center mt-4">
        <small class="mb-0 button__content">{{expand ? 'hide' : 'view'}} answer</small>
        <v-icon :color="$vuetify.theme.themes.light.enumGrey3">mdi-chevron-{{expand ? 'up' : 'down'}}</v-icon>
      </span>
    </v-btn>
    <v-expand-transition>
      <div class="px-2" v-show="expand">
        <div v-for="(option, i) in options" :key="i">
          <div class="d-flex align-center my-1">
            <span class="mr-1 enum-grey-3">{{optionsLabels[i]}}.</span>
            <span :class="['option font-weight-400', {'enum-grey-1': answer.includes(option)}]">{{option}}</span>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "SampleQuestionCard",
  props: {
    question: String,
    order: [String, Number],
    expand: Boolean,
    options: Array,
    answer: Array,
    id: [String, Number],
    image:[String]
  },
  data: () => ({
    optionsLabels: ["a", "b", "c", "d", "e", "f", "g"]
  }),
  methods: {
    ...mapMutations('jobScreeningCriteria', ['toggleExpand'])
  }
}
</script>

<style scoped lang="scss">
.sample__questions_wrapper {
  background: #FFFFFF;
  border: 0.889016px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 7.11213px;
  min-height: 100px;
  padding: 15px;

  .question {
    font-weight: bold;
    font-family: "DM Sans", serif;
    color: var(--enumGrey2);
  }

  .button__content {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Enum-Grey/3 */

    color: #9CABB5;
  }

  .v-btn {
    border-radius: 4px !important;
  }

  .enum-grey-1 {
    color: #1E323F !important;
  }

  .option {
    color: #9CABB5;
    font-family: "DM Sans", serif;
  }
}
.color-wrapper{
  background: #F7FCFF;
  /* Enum-Blue/4 */

  border: 0.889016px solid #C5E7FF;
  box-sizing: border-box;
  border-radius: 7.11213px;
}
.img-size{
  width:300px;
  height:150px;
}
</style>
