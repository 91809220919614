<template>
  <div class="side__navigation__wrapper">
    <div class="sub__tabs mt-10 py-2 px-2">
      <div class="skills__and__general">
        <v-tabs>
          <v-tab
              v-for="(tab, i) in subTabs"
              :key="i"
              @click="[setItems(tab.name)]"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </div>
      <div v-if="gettingCriteria" class="tw-h-full tw-w-full d-flex justify-center align-center">
        <v-progress-circular
            indeterminate
            :color="$vuetify.theme.themes.light.enumBlue.enumBlue"
        />
      </div>
      <div class="subTabs__items" v-else>
        <div class="subTab_item my-4" v-for="(item, i) in items" :key="i">
          <div v-if="item.category">
            <div class="d-flex tw-cursor-pointer justify-space-between align-center" @click="item.expand = !item.expand">
              <span class="item__title text-capitalize">{{item.name}}</span>
              <v-icon>mdi-chevron-{{item.expand ? 'up' : 'down'}}</v-icon>
            </div>
            <v-expand-transition>
              <div class="ml-4 mt-2" v-show="item.expand">
                <div v-for="(sub_item, i) in item.items" :key="i" class="my-3">
                  <div v-if="sub_item.sub_category">
                    <div class="d-flex tw-cursor-pointer justify-space-between align-center" @click="sub_item.expand = !sub_item.expand">
                      <span class="item__title text-capitalize">{{sub_item.name}}</span>
                      <v-icon>mdi-chevron-{{sub_item.expand ? 'up' : 'down'}}</v-icon>
                    </div>
                    <v-expand-transition>
                      <div class="mt-3" v-show="sub_item.expand">
                        <tabs
                            :active-item="skillsInitialActiveItem"
                            @click="[setShowSampleQuestions(false)]"
                            :tabs="sub_item.items" @change="setCriteriaQuestions"/>
                      </div>
                    </v-expand-transition>
                  </div>
                </div>
              </div>
            </v-expand-transition>
          </div>
            <tabs v-else :active-item="skillsInitialActiveItem" :tabs="item.items" @click="[setShowSampleQuestions(false)]" @change="setCriteriaQuestions"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import {getScreeningCriteria} from "@/services/api/APIService";
import Tabs from "@/components/reusables/Tabs";

export default {
  name: "SideNavigation",
  components: {Tabs},
  data: () => ({
    gettingCriteria: false,
    skillsInitialActiveItem: '',
    subTabs: [
      {
        name: "Skills",
        items: []
      },
      {
        name: "General",
        items: []
      }
    ],
    items: []
  }),
  methods: {
    ...mapMutations('jobScreeningCriteria', ['setCriteriaQuestionsInView', 'setShowQuestionCards', 'setShowSampleQuestions']),
    ...mapActions('jobScreeningCriteria', ['fetchCriteriaTopics']),
    setItems(itemName) {
      this.items = this.subTabs.find(t => t.name === itemName).items
    },
    setCriteriaQuestions({name: criteria_name, id: criteria_id}) {
      this.setShowQuestionCards(true)
      this.setShowSampleQuestions(false)
      this.fetchCriteriaTopics({criteria_name, criteria_id})
    },
    async getCriteria(type) {
      this.gettingCriteria = true
      try {
        const {data} = await getScreeningCriteria(type)
        this.subTabs[0].items = [{name: "Skills", items: data.map(({id, title}) => ({id, title}))}]
      } catch (e) {
        this.$displaySnackbar({message: e.response.data.detail, success: false})
        throw e
      } finally {
        this.gettingCriteria = false
      }
    }
  },
  async created() {
    await this.getCriteria('skill')
    const {data: generalCategories} = await getScreeningCriteria('general')
    const categoryKeys = Object.keys(generalCategories)
    this.subTabs[1].items = categoryKeys.map(key => {
      let subCategories = generalCategories[key]
      let subCategoriesKeys = Object.keys(subCategories)
      let items = subCategoriesKeys.map(key => {
        return {
          name: key,
          sub_category: true,
          expand: false,
          items: subCategories[key]
        }
      })
      return {
        name: key,
        category: true,
        expand: false,
        items
      }
    })
    this.setItems('Skills')
    try {
      const {id, title} = this.items[0].items[0]
      this.skillsInitialActiveItem = title
      await this.fetchCriteriaTopics({criteria_name: title, criteria_id: id})
    } catch (e) {
      this.$displaySnackbar({message: e.response.data.detail, success: false})
    }
  },
  // switchTab(name) {
  //
  // }
}
</script>

<style scoped lang="scss">
.side__navigation__wrapper {
  width: 100%;
  max-width: 380px;

  .v-tab {
    text-transform: capitalize;
  }

  .sub__tabs {
    min-height: 400px;
    border-radius: 8px;
    padding: 0 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

    .subTab_item {
      border: 1px solid #f1f5f6;
      border-radius: 4px;
      padding: 10px 8px;
      //box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    }
  }

  .item__title {
    color: #475661;
    font-weight: 700;
    font-family: "DM Sans", serif;
  }
}
</style>