<template>
  <v-hover v-slot="{ hover }">
    <div class="criteria__question__card py-2 px-5 mb-8 tw-cursor-pointer" :class="{'hover': hover}" @click="$emit('click')">
      <h3 class="font-weight-100">{{ title }}</h3>
      <div class="d-flex justify-space-between criteria__question__card-sub-div">
      <span>
        {{ number_of_questions }} Questions
      </span>
        <span class="d-flex">
        <vue-feather size="20" type="clock"/>
        <span class="ml-1">{{ duration }} mins</span>
      </span>
      </div>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "CriteriaQuestionCard",
  props: {
    number_of_questions: [Number, String],
    title: String,
    duration: String
  }
}
</script>

<style scoped lang="scss">
.criteria__question__card {
  border-radius: 10px;
  border: 1px solid #D0DCE4;
  width: 100%;
  min-height: 120px;
  font-family: "DM Sans", serif;

  h3 {
    font-family: "DM Sans", serif;
    line-height: 23px;
    margin-top: 10px;
    color: #1E323F;

    @media (min-width: 600px) {
      font-size: 20px;
    }
  }

  &-sub-div {
    margin-top: 20px;
    width: 250px;

    span {
      font-family: "DM Sans", serif;
      color: #475661;
      font-weight: 400;
    }
  }

  &.hover {
    background: #F6FCFF;
    border: 1px solid #C5E7FF;
  }
}
</style>