<template>
  <div class="d-flex">
    <side-navigation/>
    <div v-if="!showSampleQuestions" class="right__view mt-10 d-none d-sm-block">
      <div v-if="fetchingCriteriaTopics" class="tw-w-full tw-h-full d-flex justify-center align-center">
        <v-progress-circular :color="$vuetify.theme.themes.light.enumBlue.enumBlue" :size="50" indeterminate/>
      </div>
      <div v-else>
        <criteria-question-card
            v-for="(item, i) in criteriaTopicsInView.items"
            :key="i"
            :duration="item.duration"
            :number_of_questions="item.number_of_questions"
            :title="item.title"
            @click="[getQuestionsForTopic(item.id)]"/>
      </div>
    </div>
    <div v-else class="right__view mt-10 d-none d-sm-block">
      <div>
        <div class="d-flex justify-space-between align-center mb-4">
          <span class="text-uppercase sample_questions_title font-weight-500">{{ criteriaTopicsInView.title }} SAMPLE QUESTIONS</span>
          <v-btn
              :color="$vuetify.theme.themes.light.enumBlue.enumBlue"
              class="white--text text-capitalize"
              :loading="settingTestForScreening"
              @click="useTestForScreening">Use Test for Screening
          </v-btn>
        </div>
        <sample-question-card
            v-for="(question, i) in sampleQuestions"
            :id="question.id"
            :key="question.id"
            :answer="question.answer"
            :expand="question.expand"
            :options="question.options"
            :order="i + 1"
            :question="question.question"
        />
      </div>
    </div>
    <transition name="slide-in">
      <mobile-view-for-criteria-question-card-list v-if="showQuestionCards" class="d-block d-sm-none"
                                                   @get-questions="getQuestionsForTopic"/>
    </transition>
    <transition name="slide-in">
      <mobile-view-for-sample-questions-list v-if="showSampleQuestions" class="d-block d-sm-none"/>
    </transition>
  </div>
</template>

<script>
import SideNavigation from "@/components/jobScreeningCriteria/SideNavigation";
import CriteriaQuestionCard from "@/components/jobScreeningCriteria/CriteriaQuestionCard";
import SampleQuestionCard from "@/components/jobScreeningCriteria/SampleQuestionCard";
import MobileViewForSampleQuestionsList from "@/components/jobScreeningCriteria/MobileViewForSampleQuestionsList";
import MobileViewForCriteriaQuestionCardList
  from "@/components/jobScreeningCriteria/MobileViewForCriteriaQuestionCardList";
import {mapMutations, mapState} from "vuex";
import {fetchCriteriaTopicQuestions, setScreeningQuestionForJob} from "@/services/api/APIService";

export default {
  name: "TestLibrary",
  components: {
    MobileViewForCriteriaQuestionCardList,
    MobileViewForSampleQuestionsList,
    SampleQuestionCard,
    CriteriaQuestionCard,
    SideNavigation
  },
  data: () => ({
    topicId: null,
    settingTestForScreening: false
  }),
  computed: {
    ...mapState('jobScreeningCriteria',
        [
          'criteriaTopicsInView',
          'showQuestionCards',
          'showSampleQuestions',
          'sampleQuestions',
          'fetchingCriteriaTopics'
        ])
  },
  methods: {
    ...mapMutations('jobScreeningCriteria', ['setShowSampleQuestions', 'setSampleQuestions']),
    async getQuestionsForTopic(id) {
      console.log(id)
      this.topicId = id
      try {
        const {data} = await fetchCriteriaTopicQuestions(id)
        console.log(data)
        const questions = data.map(({content: question, id, answer, options, explanation}) => ({
          question,
          id,
          answer,
          options,
          explanation,
          expand: false
        }))
        this.setSampleQuestions(questions)
        this.setShowSampleQuestions(true)
      } catch (e) {
        this.$displaySnackbar({message: e, success: false})
      }
    },
    async useTestForScreening() {
      try {
        this.settingTestForScreening = true
        const {topicId} = this
        const {$route: {params: {id}}} = this
        const {data} = await setScreeningQuestionForJob(topicId, +id)
        this.$displaySnackbar({message: "You have successfully set this topic for this job.", success: true})
        console.log(data)
      } catch (e) {
        console.log(e)
        this.$displaySnackbar({message: e.response.data.detail, success: false})
      } finally {
        this.settingTestForScreening = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.right__view {
  width: calc(100vw - 400px);
  //margin-top: 120px;
  margin-left: 20px;
}

.slide-in-enter, .slide-in-leave-to {
  transform: translateX(1000px);
}

.slide-in-enter-active, .slide-in-leave-active {
  transition: all .5s ease-in-out;
}
</style>